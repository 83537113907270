// @import "node_modules/bootstrap/scss/functions";
// @import "node_modules/bootstrap/scss/variables";
// @import "node_modules/bootstrap/scss/mixins";
@import "src/_metronic/assets/sass/components/_variables.custom.scss";

.freeWinHeader {
  font-size: 44px;
}

.freeWinBtn {
  height: 78px;
  width: 325px;
  font-size: 24px;
  background-color: $freewin;
  &:hover {
    background-color: darken($freewin, 10%);
    color: white;
  }
}

@media (max-width: 576px) {
  .freeWinHeader {
    font-size: 22px;
    margin-top: 20%;
  }
  .freeWinBtn {
    height: auto;
    width: auto;
    font-size: 16px;
    margin-top: 16px;
  }
}

// @include media-breakpoint-down(xxl) {
//   .freeWinHeader {
//     font-size: 44px;
//   }
// }

// @include media-breakpoint-down(sm) {
//   .freeWinHeader {
//     font-size: 22px;
//   }
// }
